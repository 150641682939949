<template>
  <div class="animated fadeIn">
    <div class="card">
      <div class="card-body p-0">
        <div v-if="loadingRows" class="mb-5 pb-5">
          <loading-animation />
        </div>

        <div v-if="!loadingRows && !locationGroupers.length">
          <div class="row bg-light mx-0 py-3">
            <div class="col text-center">
              Nenhum Agrupador encontrado
            </div>
          </div>
        </div>

        <div v-if="!loadingRows && locationGroupers.length">
          <div class="row py-3 border mx-0 bg-dark px-3">
            Armazém
          </div>

          <draggable v-model="locationGroupers" group="groupers" @end="onGrouperDrop">
            <div v-for="(locationGrouper, index) in locationGroupers" class="row py-3 border mx-0 cursor-grab" :key="index"
              :class="{ 'bg-light': index % 2 != 0 }">
              <div class="col-sm col-6">
                <span class="font-weight-bold">Nome: </span>{{locationGrouper.name}}
              </div>
              <div class="col-sm-auto col-6 pr-0">
                <b-button size="sm" variant="primary" @click.prevent="goToGroupsPage(index)" :disabled="saving"
                  class="mr-sm-1 mobile-btn-90 mb-sm-0 mb-3">
                  <i class="fa fa-list"></i> Grupos
                </b-button>
              </div>
              <div class="col-sm-auto col-12 pl-sm-0 pl-3">
                <b-button size="sm" @click.prevent="editItem(index)" :disabled="saving"
                  class="ml-sm-1 ml-0 mr-sm-1 mr-4 mobile-btn-45">
                  <i class="fa fa-edit"></i> Editar
                </b-button>
                <b-button size="sm" variant="danger" @click.prevent="openDeleteModal(index)"
                  class="ml-sm-1 ml-2 mobile-btn-45" :disabled="saving">
                  <i class="fa fa-trash"></i> Excluír
                </b-button>
              </div>
            </div>
          </draggable>

          <div class="row py-3 border mx-0 bg-dark px-3">
            Endereço
          </div>
        </div>

        <div class="row py-3 border mx-0">
          <div class="col-sm-auto col-12">
            <button type="button" class="btn btn-success mobile-btn-100" @click="openNewLocationGrouperFormModal">
              <i class="fa fa-plus"></i> Novo Agrupador
            </button>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      centered
      size="sm"
      lazy
      :title="currentLocationGrouper && currentLocationGrouper.id ? 'Editar Agrupador' : 'Novo Agrupador'"
      header-bg-variant="info"
      footer-border-variant="info"
      v-model="showLocationGrouperFormModal">
      <b-container v-if="currentLocationGrouper">
        <div v-if="loadingCurrentGrouper || saving">
          <loading-animation />
        </div>

        <div v-else>
          <b-form-group
            id="name"
            label="Nome"
            label-for="name"
          >
            <b-form-input
              required
              id="name"
              type="text"
              v-model="currentLocationGrouper.name"/>
          </b-form-group>

          <b-form-group
            label="Agrupador Pai"
          >
            <model-list-select
              :list="selectableLocationGroupers"
              option-value="id"
              option-text="name"
              v-model="currentLocationGrouper.parentLocationGrouper"
            />
          </b-form-group>
        </div>
      </b-container>

      <template v-if="currentLocationGrouper" slot="modal-footer" slot-scope="{ ok, cancel }">
        <b-button variant="secondary" @click="closeLocationGrouperFormModal">Cancelar</b-button>
        <b-button variant="primary" @click="saveLocationGrouper"
          :disabled="loadingCurrentGrouper || saving || !currentLocationGrouper.name || !currentLocationGrouper.name.length">
          Salvar
        </b-button>
      </template>
    </b-modal>

    <confirmation-modal
      id="delete-confirmation-modal"
      msg="Tem certeza de que deseja excluír esse agrupador?"
      :ok="deleteItem"
    ></confirmation-modal>
  </div>
</template>

<script>
  import { httpClient } from '@/service'
  import { LoadingAnimation } from '@/components/loaders'
  import draggable from 'vuedraggable'
  import { ModelListSelect } from 'vue-search-select'
  import ConfirmationModal from '@/components/common/confirmation-modal'
  import shared from '@/shared/shared'

  export default {
    name: 'groupers',
    components: { LoadingAnimation, draggable, ModelListSelect, ConfirmationModal },

    data () {
      return {
        locationGroupers: [],
        loadingRows: false,
        showLocationGrouperFormModal: false,
        saving: false,
        currentLocationGrouper: {},
        selectableLocationGroupers: [],
        editIndex: null,
        deleteIndex: null,
        loadingCurrentGrouper: false
      }
    },

    mounted: async function () {
      this.$store.commit('app/SET_CURRENT_PAGE', { title: 'Lista de Agrupadores', size: 4 });
      this.findLocationGroupers();
    },

    methods: {
      findLocationGroupers() {
        this.loadingRows = true;
        httpClient.get(`${process.env.VUE_APP_API_URL}location-groupers?page=0&size=100`)
        .then(({ data }) => {
          this.loadingRows = false;
          this.locationGroupers = data.data.content;

          if (!this.locationGroupers || !this.locationGroupers.length) {
            this.loadingRows = true;
            httpClient.post(`${process.env.VUE_APP_API_URL}location-groupers/default`, {})
            .then(({ data }) => {
              this.loadingRows = false;
              this.locationGroupers = data.data;
              this.locationGroupers = shared.sortLocationGroupers(this.locationGroupers);
            })
            .catch((error) => {
              this.loadingRows = false;
              if (error.message) {
                this.$notify.error(error)
              }
              this.locationGroupers = [];
            });
          } else {
            this.locationGroupers = shared.sortLocationGroupers(this.locationGroupers);
          }
        })
        .catch((error) => {
          this.loadingRows = false;
          if (error.message) {
            this.$notify.error(error)
          }
          this.locationGroupers = [];
        });
      },

      onGrouperDrop() {
        let changedLocationGroupers = [];

        for (let i = 0; i < this.locationGroupers.length; i++) {
          if (this.locationGroupers[i].parentLocationGrouper && !this.locationGroupers[i].parentLocationGrouper.id) {
            this.locationGroupers[i].parentLocationGrouper = null;
          }

          if (i > 0) {
            if (!this.locationGroupers[i].parentLocationGrouper
              || this.locationGroupers[i].parentLocationGrouper.id !== this.locationGroupers[i - 1].id
            ) {
              this.locationGroupers[i].parentLocationGrouper = this.locationGroupers[i - 1];
              changedLocationGroupers.push(this.locationGroupers[i]);
            }
          } else if (this.locationGroupers[i].parentLocationGrouper) {
            this.locationGroupers[i].parentLocationGrouper = null;
            changedLocationGroupers.push(this.locationGroupers[i]);
          }
        }

        if (changedLocationGroupers.length) {
          this.loadingRows = true;
          httpClient.put(`${process.env.VUE_APP_API_URL}location-groupers/multiple`, changedLocationGroupers)
          .then(({ data }) => {
            this.loadingRows = false;

            if (!data.errors || !data.errors.length) {
              this.$notify.success('Agrupadores salvos com sucesso');
            } else {
              this.$notify.warn('Não foi possível salvar todos os agrupadores');
              data.errors.forEach(error => {
                this.$notify.textError(error);
              });
            }
          })
          .catch((error) => {
            this.loadingRows = false;
            if (error.message) {
              this.$notify.error(error)
            }
            this.locationGroupers = [];
          });
        }
      },

      openNewLocationGrouperFormModal() {
        this.editIndex = null;
        this.currentLocationGrouper = {
          parentLocationGrouper: {}
        };
        this.selectableLocationGroupers = JSON.parse(JSON.stringify(this.locationGroupers));
        this.selectableLocationGroupers.push({ id: null, name: 'Nenhum' });
        this.showLocationGrouperFormModal = true;
      },

      saveLocationGrouper() {
        this.saving = true;
        let promise;

        if (this.currentLocationGrouper.parentLocationGrouper && !this.currentLocationGrouper.parentLocationGrouper.id) {
          this.currentLocationGrouper.parentLocationGrouper = null;
        }

        if (this.currentLocationGrouper.id) {
          promise = httpClient.put(`${process.env.VUE_APP_API_URL}location-groupers`, this.currentLocationGrouper);
        } else {
          promise = httpClient.post(`${process.env.VUE_APP_API_URL}location-groupers`, this.currentLocationGrouper);
        }

        promise.then(({ data }) => {
          this.saving = false;
          this.$notify.success('Agrupador salvo com sucesso');

          let index = this.locationGroupers.length;

          if (this.editIndex != null) {
            index = this.editIndex;
          }

          this.locationGroupers[index] = data.data;
          this.locationGroupers = shared.sortLocationGroupers(this.locationGroupers);
          this.editIndex = null;
          this.currentLocationGrouper = {};
          this.showLocationGrouperFormModal = false;
        })
        .catch((error) => {
          this.saving = false;
          if (error.message) {
            this.$notify.error(error)
          }
        });
      },

      editItem(index) {
        this.showLocationGrouperFormModal = true;
        this.loadingCurrentGrouper = true;
        httpClient.get(`${process.env.VUE_APP_API_URL}location-groupers/${this.locationGroupers[index].id}`)
        .then(({ data }) => {
          this.loadingCurrentGrouper = false;
          this.editIndex = index;

          if (!data.data.parentLocationGrouper) {
            data.data.parentLocationGrouper = {};
          }

          this.currentLocationGrouper = data.data;

          this.selectableLocationGroupers = JSON.parse(JSON.stringify(this.locationGroupers.filter(g => g.id !== this.currentLocationGrouper.id)));
          this.selectableLocationGroupers.push({ id: null, name: 'Nenhum' });
        })
        .catch((error) => {
          this.loadingCurrentGrouper = false;
          if (error.message) {
            this.$notify.error(error)
          }
        });
      },

      openDeleteModal(index) {
        this.deleteIndex = index;
        this.$bvModal.show('delete-confirmation-modal');
      },

      deleteItem() {
        this.saving = true;
        httpClient.delete(`${process.env.VUE_APP_API_URL}location-groupers`, this.locationGroupers[this.deleteIndex].id)
        .then(({ data }) => {
          this.saving = false;
          this.$notify.success('Agrupador excluído com sucesso');
          this.locationGroupers.splice(this.deleteIndex, 1);
          this.deleteIndex = null;
        })
        .catch((error) => {
          this.saving = false;
          if (error.message) {
            this.$notify.error(error)
          }
        });
      },

      closeLocationGrouperFormModal() {
        this.editIndex = null;
        this.showLocationGrouperFormModal = false;
      },

      goToGroupsPage(index) {
        shared.setLocalStorageObj('warehouse/grouper', {
          id: this.locationGroupers[index].id,
          name: this.locationGroupers[index].name
        });
        this.$router.push('/warehouse/groups/all')
      }
    }
  }
</script>

<style scoped>

</style>
